/* HEADER */
header.accueilhead {
    position: relative;
    padding-top: 150px;
    padding-bottom: 150px;
    background-attachment: scroll;
    background-color: var(--dark);
    background-image: url('/images/bg.jpg');
    background-size: cover;
    .accueilhead-img {
        max-width: 250px;
        max-height: 250px;
    }

    .h1 {
        font-size: 3.25rem;
    }

    .h2 {
        font-size: 2.25rem;
    }

    svg {
        animation: pulse 1.5s infinite;
    }

    @keyframes pulse {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, 10px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    
    @media (min-width: 992px) {
        height: 100vh;
        min-height: 700px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

/* CALENDAR (vue.js) */
.cal-svg {
    height: 63px;
}

.date-day {
    display: none;
    @media (min-width: 360px) {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
}

.date-row {
    display: grid;
    grid-template-areas: "a" "b" "c" "d" "e";
    grid-template-columns: 1fr;
    a {
        font-size: 1.6rem;
        &.disabled {
            background-color: #f8f8f8 !important;
            cursor: not-allowed;
        }
    }
    @media (min-width: 360px) {
        grid-template-areas: "a b c d e";
        grid-template-columns: repeat(5, 1fr);
        a {
            font-size: 2rem;
            transition: all 300ms;
            &:hover {
                transform: scale(1.2);
            }
            &.resa_ok:hover:after {
                content: "Réserver";
                position: absolute;
                right: 3px;
                bottom: 2px;
                left: 3px;
                font-size: .7rem;
                color: #6cb2eb;
            }
            span {
                display: none;
            }
        }
    }
    @media (min-width: 768px) {
        a.resa_ok:hover:after {
            content: "Réserver ce jour ?";
        }
    }
}

/* TEMOIGNAGES (vues accueil et temoignages) */
#cotirousel2 {
    .carousel-inner {
        min-height: 250px;
    }
}

/* CALL TO ACTION */
#philo {
    background-attachment: fixed;
    background-color: var(--dark);
    background-image: url('/images/bg3.jpg');
    background-size: cover;
    color: #ffffff;
    text-align: center;

    span.quote {
        font-size: 30px;
        line-height: 32px;
        display: block;
        @media (min-width: 768px) {
            font-size: 36px;
            line-height: 38px;
        }
        @media (min-width: 992px) {
            font-size: 40px;
            line-height: 42px;
        }
    }
}

/* NOUS TROUVER */
#localisation {
    background-attachment: fixed;
    background-color: var(--dark);
    background-image: url('/images/bg2.jpg');
    background-size: cover;
    color: #ffffff;
}

/* SIGNUP */
#nlForm {
    .input-group {
        .form-control {
            border: 0;
            border-color: #ffffff;
            border-radius: 0;
        }

        .input-group-btn {
            .btn {
                padding: 12px 18px;
            }

            .btn-primary {
                border: 0;
                border-radius: 0;
                background-color: var(--primary);
                font-size: 1rem;

                &:hover,
                &:focus {
                    color: #ffffff;
                    // background-color: #d4570c;
                }
            }
        }
    }
}


/* ICONE TEMOIGNAGE */
.carousel-item img {
    width: 125px;
}
